import React, { useEffect } from 'react'
import './terms.scss'
import Navbar from '../landing/header/Navbar'

const Privacypolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <>
    <Navbar />
      <section className="privacypolicy">
        <div className="custom-container">
            <h3 className="tophead">Privacy policy</h3>
            <p className="privacypara">We are committed to safeguarding your privacy and ensuring that your personal data is protected in line with this privacy policy ("Policy"). This Policy outlines the types of information we may collect from you or that you may provide ("Personal Information") on the roy.vip website ("Website"), the "Roy Wallet" mobile application ("Mobile Application"), and any related products and services (collectively, "Services"). It also details our methods for collecting, using, storing, protecting, and sharing your Personal Information. Additionally, this Policy explains the options available to you regarding our use of your Personal Information and how you can access and update it.</p>

            <p className="privacypara">This Policy forms a legally binding agreement between you ("User," "you," or "your") and the Website operator and Mobile Application developer ("Operator," "we," "us," or "our"). If you are agreeing to this Policy on behalf of a business or another legal entity, you confirm that you have the authority to bind that entity to this Policy, in which case the terms "User," "you," or "your" will apply to that entity. If you lack such authority or disagree with the terms of this Policy, you must not accept it, and you cannot access or use the Services. By accessing or using the Services, you confirm that you have read, understood, and agree to comply with this Policy. This Policy does not apply to companies we do not own or control or to individuals we do not employ or manage.</p>

            <h5 className="midhead">Information Collection</h5>

            <p className="privacypara">Protecting customer data is our highest priority, and we strictly adhere to a no-logs policy. We process only the minimal amount of user data necessary to maintain our Services. Any information collected automatically is solely used to identify potential misuse and to compile statistical data about the usage and traffic of the Services. This statistical data is not aggregated in a manner that would reveal the identity of any specific user.</p>

            <h5 className="midhead">Usage and Processing of Collected Information</h5>

            <p className="privacypara">We act as a data controller and a data processor when handling Personal Information, unless we have entered into a data processing agreement with you in which case you would be the data controller and we would be the data processor.</p>

            <p className="privacypara">Our role may also differ depending on the specific situation involving Personal Information. We act in the capacity of a data controller when we ask you to submit your Personal Information that is necessary to ensure your access and use of the Services. In such instances, we are a data controller because we determine the purposes and means of the processing of Personal Information.</p>

            <p className="privacypara">We act in the capacity of a data processor in situations when you submit Personal Information through the Services. We do not own, control, or make decisions about the submitted Personal Information, and such Personal Information is processed only in accordance with your instructions. In such instances, the User providing Personal Information acts as a data controller.</p>

            <p className="privacypara">In order to make the Services available to you, or to meet a legal obligation, we may need to collect and use certain Personal Information. If you do not provide the information that we request, we may not be able to provide you with the requested products or services. Any of the information we collect from you may be used to help us run and operate the Services.</p>

            <p className="privacypara">Processing your Personal Information depends on how you interact with the Services, where you are located in the world and if one of the following applies: (a) you have given your consent for one or more specific purposes; (b) provision of information is necessary for the performance of this Policy with you and/or for any pre-contractual obligations thereof; (c) processing is necessary for compliance with a legal obligation to which you are subject; (d) processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; (e) processing is necessary for the purposes of the legitimate interests pursued by us or by a third party.</p>

            <p className="privacypara">Note that under some legislations we may be allowed to process information until you object to such processing by opting out, without having to rely on consent or any other of the legal bases. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</p>

            <h5 className="midhead">Information Disclosure</h5>
            <p className="privacypara">We prioritize your privacy and safeguard your Personal Information to the fullest extent. As such, we do not share your Personal Information with any third parties for any reason.</p>

            <h5 className="midhead">Information Retention</h5>

            <p className="privacypara">We will retain and use your Personal Information for as long as necessary to enforce our Policy, resolve disputes, or as required or permitted by law. Even after you update or delete your information, we may continue to use aggregated data that includes your Personal Information, but only in a way that does not personally identify you. Once the retention period ends, Personal Information will be deleted. As a result, the rights to access, erasure, rectification, and data portability cannot be exercised after the retention period has expired.</p>

            <h5 className="midhead">Children's Privacy</h5>

            <p className="privacypara">We do not intentionally collect Personal Information from individuals under the age of 18. If you are under 18, please refrain from submitting any Personal Information through our Services. If you believe that a child under 18 has provided us with Personal Information, please contact us so we can promptly delete it from our records.</p>

            <p className="privacypara">We encourage parents and legal guardians to supervise their children's online activity and reinforce this Policy by advising them not to provide Personal Information through the Services without permission. We also urge parents and guardians to take necessary steps to ensure their children understand the importance of not sharing Personal Information online without consent.</p>

            <h5 className="midhead">Do Not Track Signals</h5>

            <p className="privacypara">Some web browsers include a Do Not Track (DNT) feature that signals to websites that you prefer not to have your online activities tracked. It’s important to note that tracking refers to collecting personally identifiable information about users as they navigate across different websites over time, and is not the same as simply collecting or using data associated with a website. Since there is no standardized method for how browsers communicate DNT signals, our Services are not currently configured to interpret or respond to them. However, as outlined in this Policy, we already limit the collection and use of your Personal Information. To learn more about Do Not Track settings and your options, visit internetcookies.com.</p>

            <h5 className="midhead">Links to Other Resources</h5>

            <p className="privacypara">The Services may contain links to external resources that are not owned or controlled by us. Please note that we are not responsible for the privacy practices of these external sites or third parties. We recommend being cautious when leaving our Services and reading the privacy policies of any external resources that may collect your Personal Information.</p>

            <h5 className="midhead">Information Security</h5>

            <p className="privacypara">While we take steps to protect your Personal Information, no data transmission over the Internet or wireless networks can be completely secure. You acknowledge that (a) there are inherent security and privacy limitations of the Internet beyond our control; (b) the security, integrity, and privacy of any information exchanged between you and the Services cannot be guaranteed; and (c) despite our best efforts, information transmitted over the Internet may be intercepted or altered by third parties during transit.</p>
            
            <h5 className="midhead">Data Breach</h5>
            <p className="privacypara">If we become aware that the security of the Services has been compromised or that Users' Personal Information has been exposed to unauthorized third parties due to external factors such as security breaches or fraud, we reserve the right to take appropriate actions. These actions may include conducting investigations, reporting the incident, and notifying and cooperating with law enforcement. In the event of a data breach, we will make reasonable efforts to inform affected individuals if we believe there is a significant risk of harm or if notification is required by law. When applicable, we will also post a notice within the Services.</p>

            <h5 className="midhead">Changes and Amendments</h5>
            <p className="privacypara">We reserve the right to update this Policy or its terms related to the Services at any time at our discretion. When changes are made, a notification will be posted within the Services. We may also notify you through other means, such as using the contact information you have provided.</p>
            <p className="privacypara">Any updated version of this Policy will take effect immediately upon posting, unless stated otherwise. Your continued use of the Services after the revised Policy becomes effective (or after any other action specified at that time) will signify your acceptance of those changes. However, we will not use your Personal Information in a materially different way from what was outlined when it was collected, without your consent.</p>

            <h5 className="midhead">Acceptance of This Policy</h5>
            <p className="privacypara">By reading and acknowledging this Policy, you agree to all of its terms and conditions. By accessing and using the Services and submitting your information, you consent to be bound by this Policy. If you do not agree to comply with the terms outlined in this Policy, you are not authorized to access or use the Services.</p>

            <h5 className="midhead">Contacting Us</h5>
            <p className="privacypara">If you have any questions, concerns, or complaints regarding this Policy, the information we hold about you, or if you wish to exercise your rights, please contact us using the following details:  <br /> <br /><a href="mailto:support@roy.vip">support@roy.vip</a></p>

            <p className="privacypara">We will do our best to resolve any complaints or disputes and will make every effort to respect your rights as quickly as possible, and in any case, within the timeframes set by applicable data protection laws. <br /> <br />
            Last update: September 14, 2024.</p>
        </div>
      </section>
    </>
  )
}

export default Privacypolicy
