import React from 'react'
import "./last.scss"

const Last = () => {
    return (
        <>
            <section className="last-section">
                <div className="custom-container">
                    <div className="parent">
                    <img src="\assets\landing\last-section-layer.svg" alt="img" className='img-fluid last-section-layer' />
                        <div className="left-side">
                            <div className="wrapper-img">
                                <img width={515} height={579} src="\assets\landing\last-main.png" alt="img" className='img-fluid' />
                            </div>
                        </div>
                        <div className="right-side">
                            <div className="main-content">
                                <h4>Multi-chain digital wallet for tokens, NFT's and Dapp Browser</h4>
                                <p>buy, sell, earn and store tokens from various blockchains on one wallet</p>
                                <div className="downloads">
                                    <a href="#"><img src="\assets\downloads\googleplay.svg" alt="img" className="img-fluid" /></a>
                                    <a href="#"><img src="\assets\downloads\appstore.svg" alt="img" className="img-fluid" /></a>
                                    <a href="#"><img src="\assets\downloads\chrome.svg" alt="img" className="img-fluid" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Last
