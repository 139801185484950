import React from 'react'
import "./twice.scss"

const Twice = () => {
    return (
        <>
            <section className="twice bg-f8f8f8">
                <div className="custom-container">
                    <div className="parent">
                        <div className="right-side">
                            <div className="wrapper-img">
                                <img width={494} height={546} src="\assets\landing\walletsecurity.png" alt="img" className='img-fluid' />
                            </div>
                        </div>
                        <div className="left-side">
                            <div className="main-content">
                                <h4>Top-Tier Wallet Security: Protecting Your Digital Assets</h4>
                                <p>With Roy Wallet’s top-tier security, your digital assets are in safe hands. We implement the latest encryption technologies and rigorous security protocols to protect your tokens and NFTs from any potential threats. Our commitment to your security ensures that every transaction is secure and your assets remain confidential. Experience the pinnacle of wallet protection with Roy Wallet.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="twice">
                <div className="custom-container">
                    <div className="parent">
                        <div className="left-side">
                            <div className="main-content">
                                <h4>Experience the Art of NFT Storage with Our Digital Library </h4>
                                <p>Take your NFT management to the next level with Roy Wallet’s digital library. Our uniquely designed space offers a secure, visually appealing way to showcase and manage your NFT collection. Enjoy both top-tier protection and a refined presentation of your digital assets.</p>
                            </div>
                        </div>
                        <div className="right-side">
                            <div className="wrapper-img">
                                <img width={494} height={547} src="\assets\landing\stroenft.png" alt="img" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="twice bg-f8f8f8">
                <div className="custom-container">
                    <div className="parent">
                        <div className="right-side">
                            <div className="wrapper-img">
                                <img width={494} height={554} src="\assets\landing\send.png" alt="img" className='img-fluid' />
                            </div>
                        </div>
                        <div className="left-side">
                            <div className="main-content">
                                <h4>Manage Your Crypto with Ease: Send, Receive, Swap, and Stake in Seconds</h4>
                                <p>With Roy Wallet, managing your crypto is quick and simple. Perform transactions, swap tokens, and stake your assets in seconds, all from a single, easy-to-use platform. Our intuitive interface ensures that handling your crypto transactions is fast, secure, and hassle-free. </p>
                                <div className="bottom-twice">
                                    
                                        <div className="single">
                                            <div className="circle-img">
                                                <img width={52} height={52} src="\assets\landing\send-icon.png" alt="img" className='img-fluid' />
                                            </div>
                                            <p>Send</p>
                                        </div>
                                    
                                    
                                        <div className="single">
                                            <div className="circle-img">
                                            <img width={52} height={52} src="\assets\landing\recieve-icon.png" alt="img" className='img-fluid' />
                                            </div>
                                            <p>Receive</p>
                                        </div>
                                    
                                    
                                        <div className="single">
                                            <div className="circle-img">
                                            <img width={52} height={52} src="\assets\landing\swap-icon.png" alt="img" className='img-fluid' />
                                            </div>
                                            <p>Swap</p>
                                        </div>
                                    
                                    
                                        <div className="single">
                                            <div className="circle-img">
                                            <img width={52} height={52} src="\assets\landing\staking-icon.png" alt="img" className='img-fluid' />
                                            </div>
                                            <p>Staking</p>
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <section className="twice">
                <div className="custom-container">
                    <div className="parent">
                        <div className="left-side">
                            <div className="main-content">
                                <h4>Explore Dapps, Maximize DeFi Earnings, and Enjoy Gaming All in One Place</h4>
                                <p>Roy Wallet combines exploration, earnings, and entertainment into one seamless experience. Explore a variety of Dapps, maximize your DeFi profits, and play thrilling games all from one convenient place. We deliver everything you need in one powerful app.
                                </p>
                            </div>
                        </div>
                        <div className="right-side">
                            <div className="wrapper-img">
                                <img width={494} height={555} src="\assets\landing\browse.png" alt="img" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="twice inmobiledirectionchnage">
                <div className="custom-container">
                    <div className="parent">
                        <div className="right-side">
                            <div className="wrapper-img">
                                <img width={494} height={540} src="\assets\landing\private.png" alt="img" className='img-fluid' />
                            </div>
                        </div>
                        <div className="left-side">
                            <div className="main-content">
                                <h4>Securely Encrypt, Send, and Decrypt Tokens and NFTs with Our DOP-Powered Private Wallet
                                </h4>
                                <p>Our private wallet feature, powered by Data Ownership Protocols (DOP), allows you to encrypt, send, and decrypt tokens and NFTs, all while maintaining complete control and privacy over your digital assets. Ensuring that your transactions and data are fully protected, and your digital security and ownership are our top priorities. </p>                               
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default Twice
