import React from 'react'
import "./features.scss"

const Features = () => {
    return (
        <>
            <section className="features">
                <div className="custom-container">
                    <div className="main-heading">
                        <h6>Roy Key Features</h6>
                    </div>
                    <div className="bottom-content">
                        <div className="single-card">
                            <div className="circle-img">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <path d="M15.1666 13.7083C14.945 13.7083 14.7233 13.6267 14.5483 13.4517C14.21 13.1133 14.21 12.5533 14.5483 12.215L24.115 2.64834C24.4533 2.31001 25.0133 2.31001 25.3516 2.64834C25.69 2.98668 25.69 3.54668 25.3516 3.88501L15.785 13.4517C15.61 13.6267 15.3883 13.7083 15.1666 13.7083Z" fill="white" />
                                    <path d="M25.6666 8.80834C25.1883 8.80834 24.7916 8.41168 24.7916 7.93334V3.20834H20.0667C19.5883 3.20834 19.1917 2.81168 19.1917 2.33334C19.1917 1.85501 19.5883 1.45834 20.0667 1.45834H25.6666C26.145 1.45834 26.5416 1.85501 26.5416 2.33334V7.93334C26.5416 8.41168 26.145 8.80834 25.6666 8.80834Z" fill="white" />
                                    <path d="M17.5 26.5417H10.5C4.16504 26.5417 1.45837 23.835 1.45837 17.5V10.5C1.45837 4.16501 4.16504 1.45834 10.5 1.45834H12.8334C13.3117 1.45834 13.7084 1.85501 13.7084 2.33334C13.7084 2.81168 13.3117 3.20834 12.8334 3.20834H10.5C5.12171 3.20834 3.20837 5.12168 3.20837 10.5V17.5C3.20837 22.8783 5.12171 24.7917 10.5 24.7917H17.5C22.8784 24.7917 24.7917 22.8783 24.7917 17.5V15.1667C24.7917 14.6883 25.1884 14.2917 25.6667 14.2917C26.145 14.2917 26.5417 14.6883 26.5417 15.1667V17.5C26.5417 23.835 23.835 26.5417 17.5 26.5417Z" fill="white" />
                                </svg>
                            </div>
                            <h6>Send</h6>
                            <p>Send tokens easily and securely through Roy Wallet. Enjoy fast, direct transactions with full autonomy over your assets</p>
                        </div>
                        <div className="single-card">
                            <div className="circle-img">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <path d="M16.1 12.775C15.8783 12.775 15.6567 12.6933 15.4817 12.5183C15.1433 12.18 15.1433 11.62 15.4817 11.2817L25.0483 1.71499C25.3867 1.37666 25.9467 1.37666 26.285 1.71499C26.6233 2.05333 26.6233 2.61333 26.285 2.95166L16.7183 12.5183C16.555 12.6817 16.3333 12.775 16.1 12.775Z" fill="white" />
                                    <path d="M20.8016 13.7083H15.1666C14.6883 13.7083 14.2916 13.3117 14.2916 12.8333V7.19833C14.2916 6.72 14.6883 6.32333 15.1666 6.32333C15.645 6.32333 16.0416 6.72 16.0416 7.19833V11.9583H20.8016C21.28 11.9583 21.6766 12.355 21.6766 12.8333C21.6766 13.3117 21.28 13.7083 20.8016 13.7083Z" fill="white" />
                                    <path d="M17.5 26.5417H10.5C4.16504 26.5417 1.45837 23.835 1.45837 17.5V10.5C1.45837 4.16501 4.16504 1.45834 10.5 1.45834H12.8334C13.3117 1.45834 13.7084 1.85501 13.7084 2.33334C13.7084 2.81168 13.3117 3.20834 12.8334 3.20834H10.5C5.12171 3.20834 3.20837 5.12168 3.20837 10.5V17.5C3.20837 22.8783 5.12171 24.7917 10.5 24.7917H17.5C22.8784 24.7917 24.7917 22.8783 24.7917 17.5V15.1667C24.7917 14.6883 25.1884 14.2917 25.6667 14.2917C26.145 14.2917 26.5417 14.6883 26.5417 15.1667V17.5C26.5417 23.835 23.835 26.5417 17.5 26.5417Z" fill="white" />
                                </svg>
                            </div>
                            <h6>Receive</h6>
                            <p>Easily receive tokens with Roy Wallet. Streamlined and secure, managing your incoming assets has never been simpler</p>
                        </div>
                        <div className="single-card">
                            <div className="circle-img">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <path d="M3.20845 10.5117C3.20845 10.29 3.29012 10.0683 3.46512 9.89334L9.31012 4.04834C9.64845 3.71 10.2085 3.71 10.5468 4.04834C10.8851 4.38667 10.8851 4.94667 10.5468 5.285L4.70179 11.13C4.36345 11.4683 3.80345 11.4683 3.46512 11.13C3.30179 10.955 3.20845 10.7333 3.20845 10.5117Z" fill="white" />
                                    <path d="M3.20842 10.5117C3.20842 10.0333 3.60508 9.63666 4.08342 9.63666L23.9167 9.63666C24.3951 9.63666 24.7917 10.0333 24.7917 10.5117C24.7917 10.99 24.3951 11.3867 23.9167 11.3867L4.08342 11.3867C3.60508 11.3867 3.20842 10.99 3.20842 10.5117Z" fill="white" />
                                    <path d="M17.1967 23.345C17.1967 23.1233 17.2784 22.9017 17.4534 22.7267L23.2984 16.8817C23.6367 16.5433 24.1967 16.5433 24.5351 16.8817C24.8734 17.22 24.8734 17.78 24.5351 18.1183L18.6901 23.9633C18.3517 24.3017 17.7917 24.3017 17.4534 23.9633C17.2784 23.7883 17.1967 23.5667 17.1967 23.345Z" fill="white" />
                                    <path d="M3.20842 17.4883C3.20842 17.01 3.60508 16.6133 4.08342 16.6133L23.9167 16.6133C24.3951 16.6133 24.7917 17.01 24.7917 17.4883C24.7917 17.9667 24.3951 18.3633 23.9167 18.3633L4.08342 18.3633C3.60508 18.3633 3.20842 17.9783 3.20842 17.4883Z" fill="white" />
                                </svg>
                            </div>
                            <h6>Swap</h6>
                            <p>With Roy Wallet, swapping tokens is simple and secure. Manage your asset exchanges effortlessly in one place</p>
                        </div>
                        <div className="single-card">
                            <div className="circle-img">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                    <path d="M13.9999 26.5533C12.7282 26.5533 11.4682 26.18 10.4766 25.445L5.45989 21.7C4.12989 20.7083 3.09155 18.6433 3.09155 16.9867V8.30666C3.09155 6.50999 4.40989 4.59666 6.10155 3.96666L11.9232 1.78499C13.0782 1.35333 14.8982 1.35333 16.0532 1.78499L21.8866 3.96666C23.5782 4.59666 24.8966 6.50999 24.8966 8.30666V16.975C24.8966 18.6317 23.8582 20.6967 22.5282 21.6883L17.5116 25.4333C16.5316 26.18 15.2716 26.5533 13.9999 26.5533ZM12.5416 3.42999L6.71989 5.61166C5.71655 5.98499 4.84155 7.24499 4.84155 8.31833V16.9867C4.84155 18.095 5.62322 19.6467 6.49822 20.3L11.5149 24.045C12.8566 25.0483 15.1316 25.0483 16.4732 24.045L21.4899 20.3C22.3766 19.635 23.1466 18.0833 23.1466 16.9867V8.30666C23.1466 7.24499 22.2716 5.98499 21.2682 5.59999L15.4466 3.41833C14.6766 3.13833 13.3232 3.13833 12.5416 3.42999Z" fill="white" />
                                    <path d="M14.0001 15.4583C12.2267 15.4583 10.7917 14.0233 10.7917 12.25C10.7917 10.4767 12.2267 9.04166 14.0001 9.04166C15.7734 9.04166 17.2084 10.4767 17.2084 12.25C17.2084 14.0233 15.7734 15.4583 14.0001 15.4583ZM14.0001 10.7917C13.1951 10.7917 12.5417 11.445 12.5417 12.25C12.5417 13.055 13.1951 13.7083 14.0001 13.7083C14.8051 13.7083 15.4584 13.055 15.4584 12.25C15.4584 11.445 14.8051 10.7917 14.0001 10.7917Z" fill="white" />
                                    <path d="M14 18.9583C13.5217 18.9583 13.125 18.5617 13.125 18.0833V14.5833C13.125 14.105 13.5217 13.7083 14 13.7083C14.4783 13.7083 14.875 14.105 14.875 14.5833V18.0833C14.875 18.5617 14.4783 18.9583 14 18.9583Z" fill="white" />
                                </svg>
                            </div>
                            <h6>Staking</h6>
                            <p>Boost your earnings by staking tokens with Roy Wallet. Earn rewards effortlessly while keeping your assets safe.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Features
