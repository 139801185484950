import './App.scss';
import Landing from './components/landing/Landing.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/landing/footer/Footer.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Privacypolicy from './components/Privacypolicy.js/Privacypolicy.js';
import Termsofuse from './components/Privacypolicy.js/Termsofuse.js';


function App() {
  return (
    <>
        <ToastContainer style={{ fontSize: 20 }} />
          <Router>
            {/* <Navbar/> */}
            <Routes>
              <Route exact path='/' element={<Landing />} />
              <Route exact path='/privacy' element={<Privacypolicy />} />
              <Route exact path='/termofuse' element={<Termsofuse />} />
            </Routes>
            <Footer/>
          </Router>
    </>
  );
}

export default App;
