import React, { useState } from 'react'
import './partners.scss'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";





const Partners = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const settings = {
        dots: false,
        arrows: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 5000,
        pauseOnHover: false,
        cssEase: 'linear',
        variableWidth: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,

                }
            }
        ]
    };

    return (
        <>
            <div className="main-heading-partners">
                <h6>Supported Blockchains</h6>
                <p>One Wallet to rule them all</p>
            </div>
            <section className="partners" id='partners'>
                <Slider {...settings}>
                    <h6>
                        <div className="main-img">
                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="31" viewBox="0 0 34 31" fill="none">
                                <path d="M9.11899 15.2568C9.04803 16.4897 9.99706 17.5451 11.2299 17.6161C11.2743 17.6161 11.3186 17.6161 11.363 17.6161C12.6047 17.6161 13.6069 16.6138 13.5981 15.3721C13.5981 14.1304 12.5958 13.1282 11.3541 13.137C10.1656 13.137 9.18108 14.0683 9.11899 15.2568Z" fill="white" />
                                <path d="M0.763516 14.6803C0.364391 14.6537 0.0184828 14.9641 0.000743943 15.3632C-0.0169949 15.7624 0.284566 16.1083 0.683691 16.126C1.08282 16.1526 1.41985 15.8422 1.44646 15.4431C1.47307 15.0439 1.16264 14.7069 0.763516 14.6803Z" fill="white" />
                                <path d="M8.97781 2.25412C9.33259 2.07673 9.4745 1.63326 9.29711 1.27848C9.11972 0.923703 8.67625 0.781792 8.32147 0.959181C7.96669 1.13657 7.82478 1.57117 8.00217 1.92595C8.17956 2.2896 8.61416 2.44038 8.97781 2.25412Z" fill="white" />
                                <path d="M11.2478 6.21886C11.7977 5.9439 12.0195 5.26983 11.7445 4.71992C11.4696 4.17001 10.7955 3.94828 10.2456 4.22323C9.69567 4.49818 9.47394 5.17226 9.74889 5.72217C10.0238 6.27207 10.6979 6.49381 11.2478 6.21886Z" fill="white" />
                                <path d="M3.60259 8.94166C4.02833 9.21661 4.59597 9.10131 4.87979 8.67557C5.15474 8.24984 5.03944 7.68219 4.61371 7.39837C4.18798 7.12342 3.62033 7.23872 3.33651 7.66445C3.05269 8.09019 3.17686 8.6667 3.60259 8.94166Z" fill="white" />
                                <path d="M4.95901 14.2811C4.33815 14.2457 3.81485 14.7157 3.77937 15.3366C3.74389 15.9575 4.21397 16.4808 4.83483 16.5162C5.4557 16.5517 5.97899 16.0816 6.01447 15.4608C6.04995 14.8488 5.57987 14.3166 4.95901 14.2811Z" fill="white" />
                                <path d="M3.7259 21.7936C3.27356 22.0242 3.0873 22.5741 3.31791 23.0353C3.54851 23.4877 4.09842 23.6739 4.55963 23.4433C5.01197 23.2127 5.19823 22.6628 4.96762 22.2016C4.73702 21.7492 4.17824 21.563 3.7259 21.7936Z" fill="white" />
                                <path d="M8.07223 11.9662C8.68422 12.3654 9.50021 12.1969 9.89046 11.5849C10.2896 10.9729 10.1211 10.1569 9.50907 9.76662C8.89708 9.3675 8.08109 9.53602 7.69084 10.148C7.29172 10.7511 7.46023 11.5671 8.07223 11.9662Z" fill="white" />
                                <path d="M21.8639 6.13003C22.3784 6.46707 23.079 6.32516 23.4161 5.81073C23.7531 5.2963 23.6112 4.59562 23.0968 4.25858C22.5824 3.92154 21.8817 4.06345 21.5446 4.57788C21.1987 5.10117 21.3406 5.79299 21.8639 6.13003Z" fill="white" />
                                <path d="M24.1521 2.18323C24.4891 2.40497 24.9326 2.3074 25.1544 1.97036C25.3761 1.63332 25.2785 1.18985 24.9415 0.968116C24.6045 0.74638 24.161 0.843944 23.9392 1.17211C23.7264 1.50915 23.8239 1.96149 24.1521 2.18323Z" fill="white" />
                                <path d="M22.0329 13.1192C20.8001 13.0483 19.7358 13.9973 19.6737 15.2301C19.6027 16.463 20.5518 17.5273 21.7846 17.5894C21.829 17.5894 21.8733 17.5894 21.9088 17.5894C23.1416 17.5894 24.1439 16.5872 24.1439 15.3454C24.1527 14.1658 23.2215 13.1813 22.0329 13.1192Z" fill="white" />
                                <path d="M11.9837 11.8066C12.3651 12.5605 13.1368 13.0394 13.9882 13.0394C15.2211 13.0394 16.2233 12.0372 16.2233 10.7954C16.2233 10.4495 16.1435 10.1036 15.9838 9.78433C15.4251 8.67565 14.0769 8.23218 12.9771 8.79095C11.8684 9.3586 11.4249 10.7068 11.9837 11.8066Z" fill="white" />
                                <path d="M29.5353 8.94167C29.9876 8.71106 30.165 8.15228 29.9344 7.69994C29.7038 7.2476 29.1451 7.07021 28.6927 7.30082C28.2404 7.53142 28.063 8.08133 28.2847 8.53367C28.5242 8.98601 29.0741 9.17227 29.5353 8.94167Z" fill="white" />
                                <path d="M23.859 9.65116C23.2115 9.97933 22.9454 10.7687 23.2736 11.4162C23.6018 12.0637 24.3912 12.3297 25.0386 12.0016C25.6861 11.6734 25.9522 10.884 25.624 10.2365C25.2958 9.58908 24.5065 9.33186 23.859 9.65116Z" fill="white" />
                                <path d="M16.5425 1.84615C17.0481 1.87276 17.4827 1.48251 17.5181 0.97695C17.5536 0.471392 17.1545 0.0367893 16.6489 0.00131151C16.1434 -0.0252968 15.7088 0.356089 15.6733 0.861647C15.6467 1.37607 16.0369 1.81068 16.5425 1.84615Z" fill="white" />
                                <path d="M16.5324 7.63783C17.2597 7.68218 17.8806 7.1234 17.9161 6.39611C17.9604 5.66882 17.4016 5.04796 16.6743 5.01248C15.9471 4.96813 15.3262 5.52691 15.2907 6.2542C15.2552 6.98149 15.8051 7.60236 16.5324 7.63783Z" fill="white" />
                                <path d="M9.40191 21.084C10.0494 20.7558 10.3155 19.9664 9.98729 19.3189C9.65912 18.6715 8.86974 18.4054 8.22227 18.7335C7.5748 19.0617 7.30872 19.8511 7.63689 20.4986C7.96506 21.146 8.75444 21.4121 9.40191 21.084Z" fill="white" />
                                <path d="M17.3848 9.56256C16.7107 10.6003 16.9946 11.9839 18.0323 12.658C19.07 13.3321 20.4537 13.0482 21.1277 12.0105C21.8018 10.9728 21.518 9.58916 20.4803 8.91509C20.1166 8.67561 19.6909 8.55144 19.2563 8.55144C18.5024 8.55144 17.8017 8.93283 17.3848 9.56256Z" fill="white" />
                                <path d="M21.2788 18.9287C20.72 17.8201 19.3718 17.3766 18.272 17.9354C17.1633 18.4941 16.7199 19.8423 17.2786 20.9421C17.8374 22.0508 19.1856 22.4943 20.2854 21.9355C21.3852 21.3856 21.8375 20.0552 21.2876 18.9465C21.2876 18.9376 21.2876 18.9376 21.2788 18.9287Z" fill="white" />
                                <path d="M25.1907 18.7691C24.5787 18.3699 23.7627 18.5385 23.3725 19.1504C22.9734 19.7624 23.1419 20.5784 23.7539 20.9687C24.3659 21.3678 25.1818 21.1993 25.5721 20.5873C25.9712 19.9842 25.8027 19.1682 25.1907 18.7691Z" fill="white" />
                                <path d="M29.4831 15.4075C29.5186 14.7866 29.0485 14.2633 28.4276 14.2278C27.8068 14.1924 27.2835 14.6624 27.248 15.2833C27.2125 15.9042 27.6826 16.4275 28.3035 16.4629C28.9155 16.4984 29.4476 16.0195 29.4831 15.4075Z" fill="white" />
                                <path d="M32.5781 14.6093C32.179 14.5827 31.8331 14.8931 31.8153 15.2922C31.7976 15.6914 32.0992 16.0373 32.4983 16.055C32.8974 16.0816 33.2345 15.7712 33.2611 15.3721C33.2788 14.9729 32.9772 14.6359 32.5781 14.6093Z" fill="white" />
                                <path d="M29.6606 21.7935C29.2348 21.5186 28.6672 21.6339 28.3834 22.0596C28.1084 22.4853 28.2237 23.053 28.6495 23.3368C29.0752 23.6117 29.6428 23.4964 29.9267 23.0707C30.2105 22.645 30.0863 22.0685 29.6606 21.7935Z" fill="white" />
                                <path d="M9.10028 28.552C8.76324 28.3303 8.31977 28.4278 8.09804 28.756C7.8763 29.093 7.97386 29.5365 8.30203 29.7582C8.63907 29.98 9.08254 29.8824 9.30428 29.5542C9.53488 29.2261 9.43732 28.7737 9.10028 28.552Z" fill="white" />
                                <path d="M24.2863 28.4812C23.9315 28.6586 23.7896 29.102 23.967 29.4568C24.1444 29.8116 24.5879 29.9535 24.9427 29.7761C25.2974 29.5987 25.4393 29.1641 25.262 28.8093C25.0846 28.4457 24.65 28.2949 24.2863 28.4812Z" fill="white" />
                                <path d="M15.8769 21.1727C16.551 20.1349 16.2671 18.7513 15.2294 18.0684C14.1917 17.3943 12.8081 17.6781 12.1251 18.7158C11.4422 19.7536 11.7349 21.1372 12.7726 21.8201C13.1362 22.0596 13.562 22.1838 13.9966 22.1838C14.7593 22.1927 15.46 21.8113 15.8769 21.1727Z" fill="white" />
                                <path d="M11.399 24.6052C10.8846 24.2681 10.1839 24.41 9.8469 24.9245C9.50986 25.4389 9.65177 26.1396 10.1662 26.4766C10.6806 26.8137 11.3813 26.6718 11.7183 26.1573C12.0643 25.6429 11.9223 24.9422 11.399 24.6052Z" fill="white" />
                                <path d="M16.6224 28.8891C16.1168 28.8625 15.6822 29.2527 15.6467 29.7583C15.6113 30.2639 16.0104 30.6985 16.516 30.7339C17.0215 30.7606 17.4561 30.3792 17.4916 29.8736C17.5182 29.3592 17.1279 28.9246 16.6224 28.8891Z" fill="white" />
                                <path d="M16.6303 23.0973C15.903 23.053 15.2821 23.6117 15.2466 24.339C15.2023 25.0663 15.7611 25.6872 16.4884 25.7227C17.2156 25.7581 17.8365 25.2082 17.872 24.4809C17.9163 23.7625 17.3576 23.1328 16.6303 23.0973Z" fill="white" />
                                <path d="M22.0139 24.5164C21.464 24.8003 21.2423 25.4743 21.5261 26.0242C21.8099 26.5741 22.484 26.7959 23.0339 26.5121C23.5838 26.2371 23.8055 25.563 23.5306 25.0131C23.2468 24.4632 22.5727 24.2326 22.0139 24.5164Z" fill="white" />
                            </svg>
                        </div>
                        Cardano</h6>
                    <h6>
                        <div className="main-img">
                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="31" viewBox="0 0 34 31" fill="none">
                                <path d="M9.11899 15.2568C9.04803 16.4897 9.99706 17.5451 11.2299 17.6161C11.2743 17.6161 11.3186 17.6161 11.363 17.6161C12.6047 17.6161 13.6069 16.6138 13.5981 15.3721C13.5981 14.1304 12.5958 13.1282 11.3541 13.137C10.1656 13.137 9.18108 14.0683 9.11899 15.2568Z" fill="white" />
                                <path d="M0.763516 14.6803C0.364391 14.6537 0.0184828 14.9641 0.000743943 15.3632C-0.0169949 15.7624 0.284566 16.1083 0.683691 16.126C1.08282 16.1526 1.41985 15.8422 1.44646 15.4431C1.47307 15.0439 1.16264 14.7069 0.763516 14.6803Z" fill="white" />
                                <path d="M8.97781 2.25412C9.33259 2.07673 9.4745 1.63326 9.29711 1.27848C9.11972 0.923703 8.67625 0.781792 8.32147 0.959181C7.96669 1.13657 7.82478 1.57117 8.00217 1.92595C8.17956 2.2896 8.61416 2.44038 8.97781 2.25412Z" fill="white" />
                                <path d="M11.2478 6.21886C11.7977 5.9439 12.0195 5.26983 11.7445 4.71992C11.4696 4.17001 10.7955 3.94828 10.2456 4.22323C9.69567 4.49818 9.47394 5.17226 9.74889 5.72217C10.0238 6.27207 10.6979 6.49381 11.2478 6.21886Z" fill="white" />
                                <path d="M3.60259 8.94166C4.02833 9.21661 4.59597 9.10131 4.87979 8.67557C5.15474 8.24984 5.03944 7.68219 4.61371 7.39837C4.18798 7.12342 3.62033 7.23872 3.33651 7.66445C3.05269 8.09019 3.17686 8.6667 3.60259 8.94166Z" fill="white" />
                                <path d="M4.95901 14.2811C4.33815 14.2457 3.81485 14.7157 3.77937 15.3366C3.74389 15.9575 4.21397 16.4808 4.83483 16.5162C5.4557 16.5517 5.97899 16.0816 6.01447 15.4608C6.04995 14.8488 5.57987 14.3166 4.95901 14.2811Z" fill="white" />
                                <path d="M3.7259 21.7936C3.27356 22.0242 3.0873 22.5741 3.31791 23.0353C3.54851 23.4877 4.09842 23.6739 4.55963 23.4433C5.01197 23.2127 5.19823 22.6628 4.96762 22.2016C4.73702 21.7492 4.17824 21.563 3.7259 21.7936Z" fill="white" />
                                <path d="M8.07223 11.9662C8.68422 12.3654 9.50021 12.1969 9.89046 11.5849C10.2896 10.9729 10.1211 10.1569 9.50907 9.76662C8.89708 9.3675 8.08109 9.53602 7.69084 10.148C7.29172 10.7511 7.46023 11.5671 8.07223 11.9662Z" fill="white" />
                                <path d="M21.8639 6.13003C22.3784 6.46707 23.079 6.32516 23.4161 5.81073C23.7531 5.2963 23.6112 4.59562 23.0968 4.25858C22.5824 3.92154 21.8817 4.06345 21.5446 4.57788C21.1987 5.10117 21.3406 5.79299 21.8639 6.13003Z" fill="white" />
                                <path d="M24.1521 2.18323C24.4891 2.40497 24.9326 2.3074 25.1544 1.97036C25.3761 1.63332 25.2785 1.18985 24.9415 0.968116C24.6045 0.74638 24.161 0.843944 23.9392 1.17211C23.7264 1.50915 23.8239 1.96149 24.1521 2.18323Z" fill="white" />
                                <path d="M22.0329 13.1192C20.8001 13.0483 19.7358 13.9973 19.6737 15.2301C19.6027 16.463 20.5518 17.5273 21.7846 17.5894C21.829 17.5894 21.8733 17.5894 21.9088 17.5894C23.1416 17.5894 24.1439 16.5872 24.1439 15.3454C24.1527 14.1658 23.2215 13.1813 22.0329 13.1192Z" fill="white" />
                                <path d="M11.9837 11.8066C12.3651 12.5605 13.1368 13.0394 13.9882 13.0394C15.2211 13.0394 16.2233 12.0372 16.2233 10.7954C16.2233 10.4495 16.1435 10.1036 15.9838 9.78433C15.4251 8.67565 14.0769 8.23218 12.9771 8.79095C11.8684 9.3586 11.4249 10.7068 11.9837 11.8066Z" fill="white" />
                                <path d="M29.5353 8.94167C29.9876 8.71106 30.165 8.15228 29.9344 7.69994C29.7038 7.2476 29.1451 7.07021 28.6927 7.30082C28.2404 7.53142 28.063 8.08133 28.2847 8.53367C28.5242 8.98601 29.0741 9.17227 29.5353 8.94167Z" fill="white" />
                                <path d="M23.859 9.65116C23.2115 9.97933 22.9454 10.7687 23.2736 11.4162C23.6018 12.0637 24.3912 12.3297 25.0386 12.0016C25.6861 11.6734 25.9522 10.884 25.624 10.2365C25.2958 9.58908 24.5065 9.33186 23.859 9.65116Z" fill="white" />
                                <path d="M16.5425 1.84615C17.0481 1.87276 17.4827 1.48251 17.5181 0.97695C17.5536 0.471392 17.1545 0.0367893 16.6489 0.00131151C16.1434 -0.0252968 15.7088 0.356089 15.6733 0.861647C15.6467 1.37607 16.0369 1.81068 16.5425 1.84615Z" fill="white" />
                                <path d="M16.5324 7.63783C17.2597 7.68218 17.8806 7.1234 17.9161 6.39611C17.9604 5.66882 17.4016 5.04796 16.6743 5.01248C15.9471 4.96813 15.3262 5.52691 15.2907 6.2542C15.2552 6.98149 15.8051 7.60236 16.5324 7.63783Z" fill="white" />
                                <path d="M9.40191 21.084C10.0494 20.7558 10.3155 19.9664 9.98729 19.3189C9.65912 18.6715 8.86974 18.4054 8.22227 18.7335C7.5748 19.0617 7.30872 19.8511 7.63689 20.4986C7.96506 21.146 8.75444 21.4121 9.40191 21.084Z" fill="white" />
                                <path d="M17.3848 9.56256C16.7107 10.6003 16.9946 11.9839 18.0323 12.658C19.07 13.3321 20.4537 13.0482 21.1277 12.0105C21.8018 10.9728 21.518 9.58916 20.4803 8.91509C20.1166 8.67561 19.6909 8.55144 19.2563 8.55144C18.5024 8.55144 17.8017 8.93283 17.3848 9.56256Z" fill="white" />
                                <path d="M21.2788 18.9287C20.72 17.8201 19.3718 17.3766 18.272 17.9354C17.1633 18.4941 16.7199 19.8423 17.2786 20.9421C17.8374 22.0508 19.1856 22.4943 20.2854 21.9355C21.3852 21.3856 21.8375 20.0552 21.2876 18.9465C21.2876 18.9376 21.2876 18.9376 21.2788 18.9287Z" fill="white" />
                                <path d="M25.1907 18.7691C24.5787 18.3699 23.7627 18.5385 23.3725 19.1504C22.9734 19.7624 23.1419 20.5784 23.7539 20.9687C24.3659 21.3678 25.1818 21.1993 25.5721 20.5873C25.9712 19.9842 25.8027 19.1682 25.1907 18.7691Z" fill="white" />
                                <path d="M29.4831 15.4075C29.5186 14.7866 29.0485 14.2633 28.4276 14.2278C27.8068 14.1924 27.2835 14.6624 27.248 15.2833C27.2125 15.9042 27.6826 16.4275 28.3035 16.4629C28.9155 16.4984 29.4476 16.0195 29.4831 15.4075Z" fill="white" />
                                <path d="M32.5781 14.6093C32.179 14.5827 31.8331 14.8931 31.8153 15.2922C31.7976 15.6914 32.0992 16.0373 32.4983 16.055C32.8974 16.0816 33.2345 15.7712 33.2611 15.3721C33.2788 14.9729 32.9772 14.6359 32.5781 14.6093Z" fill="white" />
                                <path d="M29.6606 21.7935C29.2348 21.5186 28.6672 21.6339 28.3834 22.0596C28.1084 22.4853 28.2237 23.053 28.6495 23.3368C29.0752 23.6117 29.6428 23.4964 29.9267 23.0707C30.2105 22.645 30.0863 22.0685 29.6606 21.7935Z" fill="white" />
                                <path d="M9.10028 28.552C8.76324 28.3303 8.31977 28.4278 8.09804 28.756C7.8763 29.093 7.97386 29.5365 8.30203 29.7582C8.63907 29.98 9.08254 29.8824 9.30428 29.5542C9.53488 29.2261 9.43732 28.7737 9.10028 28.552Z" fill="white" />
                                <path d="M24.2863 28.4812C23.9315 28.6586 23.7896 29.102 23.967 29.4568C24.1444 29.8116 24.5879 29.9535 24.9427 29.7761C25.2974 29.5987 25.4393 29.1641 25.262 28.8093C25.0846 28.4457 24.65 28.2949 24.2863 28.4812Z" fill="white" />
                                <path d="M15.8769 21.1727C16.551 20.1349 16.2671 18.7513 15.2294 18.0684C14.1917 17.3943 12.8081 17.6781 12.1251 18.7158C11.4422 19.7536 11.7349 21.1372 12.7726 21.8201C13.1362 22.0596 13.562 22.1838 13.9966 22.1838C14.7593 22.1927 15.46 21.8113 15.8769 21.1727Z" fill="white" />
                                <path d="M11.399 24.6052C10.8846 24.2681 10.1839 24.41 9.8469 24.9245C9.50986 25.4389 9.65177 26.1396 10.1662 26.4766C10.6806 26.8137 11.3813 26.6718 11.7183 26.1573C12.0643 25.6429 11.9223 24.9422 11.399 24.6052Z" fill="white" />
                                <path d="M16.6224 28.8891C16.1168 28.8625 15.6822 29.2527 15.6467 29.7583C15.6113 30.2639 16.0104 30.6985 16.516 30.7339C17.0215 30.7606 17.4561 30.3792 17.4916 29.8736C17.5182 29.3592 17.1279 28.9246 16.6224 28.8891Z" fill="white" />
                                <path d="M16.6303 23.0973C15.903 23.053 15.2821 23.6117 15.2466 24.339C15.2023 25.0663 15.7611 25.6872 16.4884 25.7227C17.2156 25.7581 17.8365 25.2082 17.872 24.4809C17.9163 23.7625 17.3576 23.1328 16.6303 23.0973Z" fill="white" />
                                <path d="M22.0139 24.5164C21.464 24.8003 21.2423 25.4743 21.5261 26.0242C21.8099 26.5741 22.484 26.7959 23.0339 26.5121C23.5838 26.2371 23.8055 25.563 23.5306 25.0131C23.2468 24.4632 22.5727 24.2326 22.0139 24.5164Z" fill="white" />
                            </svg>
                        </div>
                        Cardano</h6>
                    <h6>
                        <div className="main-img">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="23" viewBox="0 0 30 23" fill="none">
                                <path d="M5.39473 16.9055C5.56528 16.735 5.79978 16.6355 6.0485 16.6355H28.6034C29.0155 16.6355 29.2216 17.1329 28.9303 17.4243L24.4747 21.8798C24.3042 22.0504 24.0697 22.1499 23.821 22.1499H1.26607C0.85391 22.1499 0.647832 21.6524 0.939184 21.3611L5.39473 16.9055Z" fill="white" />
                                <path d="M5.39473 0.270034C5.57239 0.0994861 5.80689 0 6.0485 0H28.6034C29.0155 0 29.2216 0.497431 28.9303 0.788783L24.4747 5.24434C24.3042 5.41489 24.0697 5.51437 23.821 5.51437H1.26607C0.85391 5.51437 0.647832 5.01694 0.939184 4.72559L5.39473 0.270034Z" fill="white" />
                                <path d="M24.4747 8.53444C24.3042 8.36389 24.0697 8.2644 23.821 8.2644H1.26607C0.85391 8.2644 0.647832 8.76183 0.939184 9.05319L5.39473 13.5087C5.56528 13.6793 5.79978 13.7788 6.0485 13.7788H28.6034C29.0155 13.7788 29.2216 13.2813 28.9303 12.99L24.4747 8.53444Z" fill="white" />
                            </svg>
                        </div>
                        Solana</h6>
                    <h6>
                        <div className="main-img">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="22" viewBox="0 0 25 22" fill="none">
                                <path d="M18.25 6.4375C17.8125 6.1875 17.25 6.1875 16.75 6.4375L13.25 8.5L10.875 9.8125L7.4375 11.875C7 12.125 6.4375 12.125 5.9375 11.875L3.25 10.25C2.8125 10 2.5 9.5 2.5 8.9375V5.8125C2.5 5.3125 2.75 4.8125 3.25 4.5L5.9375 2.9375C6.375 2.6875 6.9375 2.6875 7.4375 2.9375L10.125 4.5625C10.5625 4.8125 10.875 5.3125 10.875 5.875V7.9375L13.25 6.5625V4.4375C13.25 3.9375 13 3.4375 12.5 3.125L7.5 0.1875C7.0625 -0.0625 6.5 -0.0625 6 0.1875L0.875 3.1875C0.375 3.4375 0.125 3.9375 0.125 4.4375V10.3125C0.125 10.8125 0.375 11.3125 0.875 11.625L5.9375 14.5625C6.375 14.8125 6.9375 14.8125 7.4375 14.5625L10.875 12.5625L13.25 11.1875L16.6875 9.1875C17.125 8.9375 17.6875 8.9375 18.1875 9.1875L20.875 10.75C21.3125 11 21.625 11.5 21.625 12.0625V15.1875C21.625 15.6875 21.375 16.1875 20.875 16.5L18.25 18.0625C17.8125 18.3125 17.25 18.3125 16.75 18.0625L14.0625 16.5C13.625 16.25 13.3125 15.75 13.3125 15.1875V13.1875L10.9375 14.5625V16.625C10.9375 17.125 11.1875 17.625 11.6875 17.9375L16.75 20.875C17.1875 21.125 17.75 21.125 18.25 20.875L23.3125 17.9375C23.75 17.6875 24.0625 17.1875 24.0625 16.625V10.6875C24.0625 10.1875 23.8125 9.6875 23.3125 9.375L18.25 6.4375Z" fill="white" />
                            </svg>
                        </div>
                        Matic</h6>
                    <h6><div className="main-img">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="27" viewBox="0 0 30 27" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.55895 26.06H7.49601C8.65843 26.06 9.2396 26.06 9.75831 25.9164C10.327 25.7473 10.8457 25.4476 11.277 25.035C11.673 24.6577 11.9587 24.1576 12.5224 23.1711L12.5228 23.1705L12.5393 23.1415L18.9426 11.8224C19.5113 10.8287 19.7925 10.3288 19.9175 9.80382C20.0549 9.23511 20.0549 8.63517 19.9175 8.06646C19.7934 7.54548 19.5093 7.04909 18.9491 6.07023L18.9363 6.04791L16.4303 1.67329C15.9116 0.76086 15.6491 0.304645 15.3179 0.135919C14.9617 -0.0453064 14.5367 -0.0453064 14.1805 0.135919C13.8492 0.304645 13.5868 0.76086 13.0681 1.67329L0.87785 23.1602C0.365405 24.0601 0.109148 24.5103 0.127914 24.8788C0.152913 25.2789 0.359138 25.6413 0.696624 25.8601C1.00908 26.06 1.52152 26.06 2.55895 26.06ZM27.038 26.06H19.9637C18.9201 26.06 18.3951 26.06 18.0889 25.8601C17.7514 25.6413 17.5452 25.2728 17.5202 24.8727C17.5016 24.5069 17.7598 24.0611 18.2766 23.169L18.2768 23.1688L18.2889 23.1479L21.8198 17.0859C22.3385 16.1922 22.601 15.7485 22.926 15.5798C23.282 15.3985 23.701 15.3985 24.0571 15.5798C24.3797 15.7441 24.631 16.169 25.1284 17.0102L25.1292 17.0114L25.1695 17.0797L28.713 23.1416L28.7198 23.1533C28.7347 23.1792 28.7495 23.2046 28.7642 23.2297L28.7642 23.2298C29.2554 24.0774 29.506 24.5099 29.4881 24.8666C29.4691 25.2663 29.2567 25.6352 28.9193 25.854C28.6067 26.06 28.0816 26.06 27.038 26.06H27.038Z" fill="white" />
                        </svg>
                    </div>Avalanche</h6>
                    <h6><div className="main-img">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="32" viewBox="0 0 20 32" fill="none">
                            <path d="M9.99799 0V11.8262L19.9936 16.2928L9.99799 0Z" fill="white" />
                            <path d="M9.99698 0L0 16.2928L9.99698 11.8262V0Z" fill="white" />
                            <path d="M9.99799 23.9565V31.9922L20.0003 18.1541L9.99799 23.9565Z" fill="white" />
                            <path d="M9.99698 31.9922V23.9552L0 18.1541L9.99698 31.9922Z" fill="white" />
                            <path d="M9.99799 22.0969L19.9936 16.2931L9.99799 11.8292V22.0969Z" fill="white" />
                            <path d="M0 16.2928L9.99698 22.0966V11.829L0 16.2928Z" fill="white" />
                        </svg>
                    </div>Ethereum</h6>
                    <h6><div className="main-img">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="35" viewBox="0 0 30 35" fill="none">
                            <path d="M3.27123 17.0894L3.28926 23.7L8.90625 27.0052V30.8754L0.00200394 25.6531V15.1563L3.27123 17.0894ZM3.27123 10.4788V14.331L0 12.3959V8.54374L3.27123 6.60864L6.55849 8.54374L3.27123 10.4788ZM11.252 8.54374L14.5232 6.60864L17.8105 8.54374L14.5232 10.4788L11.252 8.54374Z" fill="white" />
                            <path d="M5.63477 22.3297V18.4595L8.90599 20.3946V24.2467L5.63477 22.3297ZM11.2517 28.3913L14.523 30.3264L17.8102 28.3913V32.2435L14.523 34.1786L11.2517 32.2435V28.3913ZM22.5017 8.54361L25.7729 6.60852L29.0602 8.54361V12.3958L25.7729 14.3309V10.4787L22.5017 8.54361ZM25.7729 23.6998L25.791 17.0893L29.0622 15.1542V25.651L20.158 30.8733V27.0031L25.7729 23.6998Z" fill="white" />
                            <path d="M23.4275 22.3297L20.1562 24.2468V20.3947L23.4275 18.4596V22.3297Z" fill="white" />
                            <path d="M23.4272 11.849L23.4453 15.7191L17.8122 19.0244V25.651L14.541 27.5681L11.2698 25.651V19.0244L5.63677 15.7191V11.849L8.92202 9.91385L14.521 13.2352L20.154 9.91385L23.4413 11.849H23.4272ZM5.63477 5.24038L14.523 0L23.4272 5.24038L20.156 7.17548L14.523 3.85416L8.906 7.17548L5.63477 5.24038Z" fill="white" />
                        </svg>
                    </div>BNB smart chain</h6>
                    <h6><div className="main-img">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="32" viewBox="0 0 25 32" fill="none">
                            <path d="M24.0227 12.7285C24.5203 9.40153 21.9873 7.61316 18.5238 6.42015L19.6474 1.91349L16.904 1.22992L15.8103 5.61792C15.089 5.43804 14.3484 5.26855 13.6123 5.10053L14.714 0.683574L11.9724 0L10.8482 4.50519C10.2514 4.3693 9.66518 4.23501 9.09647 4.0935L9.09964 4.07933L5.31653 3.13462L4.58676 6.06467C4.58676 6.06467 6.62209 6.53122 6.5792 6.55994C7.69009 6.83721 7.89099 7.57259 7.85763 8.15548L6.57773 13.2896C6.65422 13.309 6.75345 13.3371 6.86294 13.3811C6.77141 13.3584 6.67402 13.3336 6.57296 13.3094L4.77898 20.5016C4.64322 20.8391 4.29862 21.3456 3.52193 21.1533C3.54943 21.1931 1.52803 20.6557 1.52803 20.6557L0.166016 23.7959L3.73602 24.6859C4.40017 24.8524 5.05099 25.0267 5.69192 25.1906L4.5567 29.7489L7.29686 30.4325L8.42108 25.9225C9.16966 26.1258 9.89613 26.3132 10.6073 26.4899L9.48689 30.9786L12.2303 31.6622L13.3654 27.1124C18.0434 27.9977 21.5609 27.6408 23.0414 23.4095C24.2344 20.0029 22.982 18.038 20.521 16.7566C22.3135 16.3432 23.6637 15.1642 24.0236 12.7288L24.0228 12.7282L24.0227 12.7285ZM17.7548 21.5177C16.907 24.9243 11.1713 23.0828 9.31166 22.621L10.8181 16.582C12.6776 17.0462 18.6408 17.9649 17.7549 21.5177H17.7548ZM18.6032 12.6791C17.8298 15.7778 13.0559 14.2035 11.5073 13.8175L12.8731 8.34048C14.4217 8.72651 19.4089 9.44698 18.6034 12.6791H18.6032Z" fill="white" />
                        </svg>
                    </div>Bitcoin</h6>
                </Slider>
            </section>


        </>
    )
}

export default Partners