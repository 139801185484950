import React from 'react'
import "./easy.scss"

const Easy = () => {
    return (
        <>
            <section className="easytouse">
                <div className="custom-container">
                        <div className="parent">
                            <div className="left-side">
                                <div className="main-content">
                                    <h4>Your all-in-one crypto wallet: sleek, simple, and super user-friendly!</h4>
                                    <p>With Roy Wallet, you can effortlessly store various tokens from different blockchains in one spot. Say goodbye to multiple wallets and passwords, this is your straightforward way to join the advanced crypto community.</p>
                                </div>
                            </div>
                            <div className="right-side">
                                <div className="wrapper-img">
                                    <img width={523} height={410} src="\assets\landing\beautiful-main.png" alt="img" className='img-fluid default-image' />
                                    <img width={523} height={410} src="\assets\landing\beautiful-main-hover.png" alt="img" className='img-fluid hover-image' />
                                </div>
                            </div>
                        </div>
                </div>
            </section>
        </>
    )
}

export default Easy
