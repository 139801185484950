
import React from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";
import Spline from '@splinetool/react-spline';


const Banner = () => {
  return (
    <>
      <section className="main-banner">
        <img src="\assets\landing\banner-bg.svg" alt="img" className="img-fluid banner-bg" />
        <div className="custom-container">
          <div className="parent">
            <div className="left-side">
              <div className="main-content">
                <h4>Secure, self-custody digital payments</h4>
                <p>Effortlessly Buy, sell, earn, and store tokens and NFTs including Dapps and much more</p>
                <div className="downloads">
                  <a href="#"><img src="\assets\downloads\googleplay.svg" alt="img" className="img-fluid" /></a>
                  <a href="#"><img src="\assets\downloads\appstore.svg" alt="img" className="img-fluid" /></a>
                  <a href="#"><img src="\assets\downloads\chrome.svg" alt="img" className="img-fluid" /></a>
                </div>
              </div>
            </div>
            <div className="right-side">
              <Spline className="set-spline-mobile" scene="https://prod.spline.design/Z514hPUt-e1FhSCN/scene.splinecode" />
              <div className="mobile-images d-none">
                <img src="\assets\landing\bannermbl2.png" alt="img" className="img-fluid bannermbl2" />
                <img src="\assets\landing\bannermbl1.png" alt="img" className="img-fluid bannermbl1" />
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
