// import logo from './logo.svg';
import '../../App.scss';
import React, { useState, useEffect } from "react";
import Banner from './main-banner/Banner.js';
import Navbar from './header/Navbar';
import Partners from './Partners/Partners.js';
import Easy from './Easytouse/Easy.js';
import Features from './Features/Features.js';
import Twice from './Twice/Twice.js';
import Last from './Last/Last.js';



function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <>
      <Navbar />
      <Banner />
      <Partners />
      <Easy />
      <Features />
      <Twice />
      <Last />
    </>
  );
}

export default Landing;